// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app\\\\components\\\\SubmitButton.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app\\components\\SubmitButton.tsx");
  import.meta.hot.lastModified = "1721294682000";
}
// REMIX HMR END

import { Button } from "./ui/button";
import { Loader2 } from "lucide-react";
export function SubmitButton({
  text,
  submitting,
  disabled
}) {
  return <Button type="submit" aria-disabled={submitting} disabled={submitting || disabled}>
            {submitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            {text}
        </Button>;
}
_c = SubmitButton;
var _c;
$RefreshReg$(_c, "SubmitButton");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;